import loadable from "@loadable/component";
import classnames from "classnames";
import { isLeft } from "fp-ts/lib/Either";
import { inject } from "mobx-react";
import { useEffect } from "react";

import { ShowResolver } from "@ihr-radioedit/inferno-core";
import type { BlockFragment, ShowSiteFragment } from "@ihr-radioedit/inferno-webapi";
import { getPaths } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { getThemingCssFromTags } from "../../services/Sites.utils";
import { Container, Heading, Carousel } from "../../ui";
import type { Store } from "@inferno/renderer-shared-core";

const ShowComponent = loadable(() => import("./Show.component"));

const log = ILog.logger("ShowCollection");

interface ShowCollectionProps {
  shows: ShowSiteFragment[] | null;
  store?: Store;
  block: BlockFragment;
}

export const ShowCollection = inject("store")(({ store, shows, block }: ShowCollectionProps) => {
  if (!store || !shows) {
    return null;
  }
  useEffect(() => store.storeBlock({ ...block }));

  if (!shows.length) {
    return null;
  }
  const title = block.value.title;

  return (
    <Container
      className={classnames("component-show-collection", getThemingCssFromTags(block.tags))}
      key="show-collection"
    >
      <>{title ? <Heading level={2}>{title}</Heading> : null}</>
      <Carousel minSlidesToShow={3} maxSlidesToShow={6}>
        {shows.map((show, i) => {
          const resolvedShow = ShowResolver.decode(show);
          if (isLeft(resolvedShow)) {
            const errorMessage = `Show Failed Validation for ${show.slug}\n` + getPaths(resolvedShow).join("\n");
            log.info(errorMessage);
            return null;
          }
          return (
            <ShowComponent
              key={i}
              title={resolvedShow.right.configByLookup.common.branding?.name || ""}
              thumb={resolvedShow.right.configByLookup.common.branding?.logo?.asset?.href || ""}
              url={`/featured/${resolvedShow.right.slug}/`}
            />
          );
        })}
      </Carousel>
    </Container>
  );
});

export default ShowCollection;
